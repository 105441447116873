import hasWindow from '../constants/has-window.const';
import { navigate } from 'gatsby';
import { each, unique } from 'underscore';
import { isNotEmptyArray } from './arrays.helper';

export default class QueryParamsHelper {
  static getQueryParams = getQueryParams;
  static getAllUniqueKeys = getAllUniqueKeys;
  static getOne = getOne;
  static getAll = getAll;
  static addQueryParam = addQueryParam;
  static removeQueryParam = removeQueryParam;
  static updateQueryParams = updateQueryParams;
  static removeQueryParamsByNames = removeQueryParamsByNames;
  static transformParamsStateToAPIParams = transformParamsStateToAPIParams;
}

export function getQueryParams() {
  if (!hasWindow) {
    return null;
  }

  return new URLSearchParams(location.search.substring(1));
}

export function getAllUniqueKeys() {
  if (!hasWindow) {
    return null;
  }
  const params = getQueryParams();
  const keys = [];

  for(const key of params.keys()) {
    keys.push(key);
  }

  return unique(keys);
}

export function getOne(key) {
  if (!hasWindow) {
    return '';
  }

  const params = getQueryParams();
  return params.get(key) || '';
}

export function getAll(key) {
  if (!hasWindow) {
    return '';
  }

  const params = getQueryParams();
  const queryValues = params.getAll(key) || [];

  return queryValues.reduce((uniqueValues, item) => {
    const normalizeItem = item.toLowerCase();
    return uniqueValues.includes(normalizeItem) ? uniqueValues : [...uniqueValues, normalizeItem];
  }, []);
}

export function removeQueryParam(key, value, resetPage = false) {
  if (!hasWindow) {
    return;
  }

  let params = getQueryParams();
  if (!params.has(key)) {
    return;
  }

  params = removeQueryParamFromParams(params, key, value);
  if (resetPage) {
    params = resetPageParam(params);
  }

  return setParamsToUrl(params);
}

export function removeQueryParamsByNames(queryParamNames, resetPage = false) {
  let params = getQueryParams();

  params = removeQueryParamsFromParams(params, queryParamNames);

  if (resetPage) {
    params = resetPageParam(params);
  }

  return setParamsToUrl(params);
}

export function removeQueryParamsFromParams(params, queryParamNames) {
  queryParamNames.forEach(name => {
    params.delete(name);
  });

  return params;
}

export function addQueryParam(key, value, resetPage = false) {
  if (!hasWindow) {
    return;
  }

  let params = getQueryParams();
  if (isParamExist(params, key, value)) {
    return;
  }

  params = addQueryParamToParams(params, key, value);
  if (resetPage) {
    params = resetPageParam(params);
  }

  return setParamsToUrl(params);
}

export function setQueryParam(key, value, resetPage = false) {
  if (!hasWindow) {
    return;
  }

  let params = getQueryParams();
  params.set(key, value);

  if (resetPage) {
    params = resetPageParam(params);
  }

  return setParamsToUrl(params);
}

export function updateQueryParams(paramsStates) {
  if (!hasWindow) {
    return;
  }

  let params = getQueryParams();

  paramsStates.forEach(({ key, value, state}) => {
    if (state) {
      params = addQueryParamToParams(params, key, value)
    } else {
      params = removeQueryParamFromParams(params, key, value)
    }
  })

  return setParamsToUrl(params);
}

export function transformParamsStateToAPIParams(paramsState) {
  let params = {}

  if (!paramsState) {
    return params;
  }

  each(paramsState, (item, key) => {
    if (item && typeof item === 'string') {
      params = { [key]: item.trim(), ...params };
    }

    if (isNotEmptyArray(item)) {
      params = { [key]: item.join(','), ...params };
    }
  })

  return params;
}

export function removeAll(params) {
  const keys = getAllUniqueKeys();
  keys.forEach(key => {
    params.delete(key)
  });

  return params;
}

function addQueryParamToParams(params, key, value) {
  let isExist = isParamExist(params, key, value);

  if (isExist) {
    return params;
  }

  params.append(key, value.toLowerCase());
  return params;
}

function removeQueryParamFromParams(params, key, value) {
  if (!params.has(key)) {
    return params;
  }

  const valueArray = params.getAll(key);
  const filteredValue = valueArray.filter(item => item.toLowerCase() !== value.toLowerCase());

  params.delete(key);

  if (filteredValue.length && filteredValue.length > 0) {
    filteredValue.forEach(value => {
      params.append(key, value);
    })
  }

  return params;
}

function isParamExist(params, key, value) {
  let isExist = false;
  if (params.has(key)) {
    const valueArray = params.getAll(key);
    isExist = valueArray.some(item => item.toLowerCase() === value.toLowerCase())
  }

  return isExist;
}

function setParamsToUrl(params) {
  if (!hasWindow) {
    return;
  }

  const pathname = location.pathname;
  if (pathname) {
    return navigate(`${pathname}?${params.toString()}`);
  }
}

function resetPageParam(params) {
  params.set('page', '1');
  return params;
}
