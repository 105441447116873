import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import axiosService from '../../services/axios.service';
import hasWindow from '../../constants/has-window.const';
import { handleBadRequestError } from '../../helpers/error-handler.helper';
import NotificationService from '../../services/notification.service';
import { getQueryParams } from '../../helpers/query-params.helper';
import { EMAIL_PATTERN } from '../../constants/validation.const';

const CaptureEmailModal = ({
  path = '',
  title = '',
  buttonLabel = '',
  isOpen = false,
  onCloseCallback = () => {}
}) => {
  const defaultTitle = 'Please provide your email address to continue';
  const defaultButtonLabel = 'Continue';
  const [validated, setValidated] = useState(false);
  const [isSubmitInProcess, setIsSubmitInProcess] = useState(false);
  const [termsState, setTermsState] = useState(false);
  const [email, setEmail] = useState('');
  const apiUrl = `${process.env.API_URL}/leads/trial`;

  async function handleSubmit (event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    setValidated(true);

    if (form.checkValidity()) {
      await saveEmail(email);
    }
  }

  function onClose () {
    setValidated(false);
    onCloseCallback();
  }

  async function saveEmail(email) {
    setIsSubmitInProcess(true);
    return await axiosService.post(apiUrl, { email  })
      .then((res) => {
        onSuccessSubmit(email);
      })
      .catch(error => handleBadRequestError(error, onEmailFieldError))
      .finally(() => {
        setIsSubmitInProcess(false);
      });
  }

  function onSuccessSubmit(email) {
    openPreCheckout(email);
    setEmail('');
    setTermsState(false);
    onClose();
  }

  function openPreCheckout (email) {
    if (hasWindow && path) {
      const separator = path.includes('?') ? '&' : '?';
      const params = getQueryParams();
      params.append('email', email);

      const url = `${path}${separator}${params.toString()}`;
      window.location.assign(url);
    }
  }

  function onEmailFieldError(field) {
    if (field && field.property && field.property === 'email') {
      NotificationService.warning('Invalid email. Please provide a valid email address.');
    }
  }

  function onEmailChange(field) {
    const email = field.value;
    const isValid = EMAIL_PATTERN.test(email);

    if (!isValid) {
      field.setCustomValidity('An element\'s value does not match its pattern attribute.');
    } else {
      field.setCustomValidity('');
    }

    setEmail(email);
  }

  return (
    <Modal show={isOpen} onHide={onClose} backdrop="static">
      <Modal.Header>
        <h5 className={`mb-0 font-weight-bold`}>{title || defaultTitle }</h5>
        <button type="button" className="close px-2" aria-label="Close" onClick={onClose}>
          <span className="uil uil-times" aria-hidden="true" />
        </button>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className={`form-group required`}>
            <Form.Label className="label">Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              required
              id="email"
              autoComplete="email"
              value={email}
              onChange={(event) => onEmailChange(event.target)}
              disabled={isSubmitInProcess}
            />
            <Form.Control.Feedback type="invalid">
              Please, provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className={`form-group required mb-0`}>
            <Form.Check type="checkbox" id="terms" className="pl-0">
              <Form.Check.Input
                onChange={(event) => setTermsState(event.target.checked)}
                checked={termsState}
                disabled={isSubmitInProcess}
                required
              />

              <Form.Check.Label className="checkbox">
                <span className="marker marker-inverse"/>
                <span className={`description text-dark text-normal mr-2`}>
                  I’ve read and agree with <a href="/terms" target="_blank">Term of Use</a> and <a href="/privacy" target="_blank" className="text-nowrap">Privacy Policy</a>
                </span>
              </Form.Check.Label>

              <Form.Control.Feedback type="invalid">
                You must accept the Term of Use and Privacy Policy
              </Form.Control.Feedback>
            </Form.Check>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn-tg btn-tg-sale">
            {isSubmitInProcess && (
              <React.Fragment>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                <span className={'ml-2'}>Saving...</span>
              </React.Fragment>
            )}
            {!isSubmitInProcess && (buttonLabel || defaultButtonLabel)}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

CaptureEmailModal.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  onCloseCallback: PropTypes.func,
};

export default CaptureEmailModal;
