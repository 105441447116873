import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import TemplateHeader from "../../components/TemplatePages/TemplateHeader/TemplateHeader";
import TitleSmBgAndDesc from "../../components/TemplatePages/TitleSmBgAndDesc/TitleSmBgAndDesc";
import IconBlock from "../../components/TemplatePages/IconBlock/IconBlock";
import StudentsSaying from "../../components/Pricing/StudentsSaying";
import ImageBlock from "../../components/TemplatePages/ImageBlock/ImageBlock";
import BlockCTA from "../../components/TemplatePages/BlockCTA/BlockCTA";
import CaptureEmailModal from '../../components/TemplatePages/CaptureEmailModal';

export const SalesPageShortTemplate = ({ frontmatter }) => {
  const [isCaptureEmailModal, setIsCaptureEmailModal] = useState(false);
  const [modalRedirectPath, setModalRedirectPath] = useState('')

  const openCaptureEmailModal = (path = '') => {
    setModalRedirectPath(path);
    setIsCaptureEmailModal(true);
  };
  const closeCaptureEmailModal = () => setIsCaptureEmailModal(false);

  function isCaptureEmailForm() {
    return !!(frontmatter && frontmatter.captureEmailForm && frontmatter.captureEmailForm.isCaptureEmailForm);
  }

  return (
    <React.Fragment>
      <TemplateHeader
        bgTitle={frontmatter.readyToTake.title}
        videoURL={frontmatter.readyToTake.videoURL}
        buttonLink={frontmatter.readyToTake.buttonLink}
        isCaptureEmailForm={isCaptureEmailForm()}
        openEmailFormCallback={openCaptureEmailModal}
      />
      <TitleSmBgAndDesc
        smTitle={frontmatter.guitarWorkOutSeries.smTitle}
        bgTitle={frontmatter.guitarWorkOutSeries.bgTitle}
        description={frontmatter.guitarWorkOutSeries.description}
      />
      <IconBlock
        iconBlock={frontmatter.iconBlock}
      />
      <StudentsSaying
        title={frontmatter.studentsSaying.title}
        studentsComments={frontmatter.studentsSaying.studentsComments}
      />
      <TitleSmBgAndDesc
        smTitle={frontmatter.heresWhatYouLearn.smTitle}
        bgTitle={frontmatter.heresWhatYouLearn.bgTitle}
        description={frontmatter.heresWhatYouLearn.description}
      />
      {frontmatter.imageBlock &&
      <ImageBlock
        imageContainer={frontmatter.imageBlock.imageContainer}
      />}
      <BlockCTA
        title={frontmatter.blockCTA.title}
        buttonLink={frontmatter.blockCTA.buttonLink}
        isCaptureEmailForm={isCaptureEmailForm()}
        openEmailFormCallback={openCaptureEmailModal}
      />
      <div className="mb-10"/>
      {
        isCaptureEmailForm() && (
          <CaptureEmailModal
            title={frontmatter.captureEmailForm.title}
            buttonLabel={frontmatter.captureEmailForm.buttonLabel}
            isOpen={isCaptureEmailModal}
            onCloseCallback={closeCaptureEmailModal}
            path={modalRedirectPath}
          />
        )
      }
    </React.Fragment>
  );
};

SalesPageShortTemplate.propTypes = {
  frontmatter: PropTypes.object,
};

const SalesPageShort = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout  title={frontmatter.readyToTake.title || 'Info Page'}>
      <SalesPageShortTemplate frontmatter={frontmatter} />
    </Layout>
  );
};

SalesPageShort.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default SalesPageShort;

export const pageQuery = graphql`
  query SalesPageShort($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        captureEmailForm {
          isCaptureEmailForm
          title
          buttonLabel
        }
        readyToTake {
          title
          videoURL
          buttonLink {
            label
            path
          }
        }
        guitarWorkOutSeries {
          smTitle
          bgTitle
          description
        }
        iconBlock {
          title
          description
          imageContainer {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
        }
        studentsSaying {
          title
          studentsComments {
            name
            state
            comment
            videoURL
            photo {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        heresWhatYouLearn {
          smTitle
          bgTitle
          description
        }
        imageBlock {
          imageContainer {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
        }
        blockCTA {
          title
          buttonLink{
            label
            path
          }
        } 
      }
    }
  }
`;
