import React from 'react';
import PropTypes from 'prop-types';
import './iconBlock.scss';
import PreviewCompatibleImage from "../../Common/PreviewCompatibleImage";

const IconBlock = ({ iconBlock }) => {
  return (
    <section className="position-relative icon-block">
      <div className="container py-7">
        <div className="row">
          <div className="col col-12 d-flex flex-wrap justify-content-center">
            {
              iconBlock && iconBlock.map((item, i) => (
                <section key={i} className="position-relative card-benefit px-3 py-6 py-xl-7 flex-grow-1">
                  <div>
                    {item && item.imageContainer && (
                      <PreviewCompatibleImage
                        imageInfo={{
                          className: "benefit-img mb-4",
                          image: item.imageContainer.src,
                          alt: item.imageContainer.alt,
                        }}
                      />)}
                    <h3 className="card-title text-primary mb-4">{item.title}</h3>
                  </div>
                  <p className="m-0">{item.description}</p>
                </section>
              ))
            }
          </div>
        </div>
      </div>
    </section>
  );
};

IconBlock.propTypes = {
  iconBlock: PropTypes.array,
};

export default IconBlock;
